export enum BillingPaymentTypesEnum {
    CARD = 'card',
    USDT_ERC20 = 'usdt',
    USDT_TRC20 = 'usdt_trc20',
}

export type BillingPaymentKassviaTypesEnum = Exclude<
    BillingPaymentTypesEnum,
    BillingPaymentTypesEnum.CARD
>;
