import styled from 'styled-components';

type BadgeContainerProps = {
    display?: string;
    size: 's' | 'm';
};

export const BadgeContainer = styled.div<BadgeContainerProps>`
    display: ${(props) => (props.display ? props.display : 'inline-block')};
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: max-content;

    border-radius: ${(props) => {
        switch (props.size) {
            case 's':
                return '4px';
            case 'm':
                return '8px';
        }

        return '8px';
    }};

    ${(props) => {
        switch (props.size) {
            case 'm':
                return props.theme.typography.button;
            case 's':
                return props.theme.typography.smallTextBold;
        }

        return props.theme.typography.button;
    }}

    padding: ${(props) => {
        switch (props.size) {
            case 's':
                return '4px 8px';
            case 'm':
                return '8px 16px';
        }

        return '8px 16px';
    }};

    &.success {
        background: rgba(82, 194, 127, 0.1);
        color: ${(props) => props.theme.palette.secondaryA3};
    }

    &.error {
        background: rgba(255, 83, 135, 0.1);
        color: ${(props) => props.theme.palette.secondaryA4};
    }

    &.warning {
        background: rgba(250, 188, 70, 0.1);
        color: #fabc46;
    }

    &.primary {
        background: ${(props) =>
            props.theme.components?.badge?.variant?.primary?.backgroundColor ||
            props.theme.palette.secondaryA9};
        color: ${(props) =>
            props.theme.components?.badge?.variant?.primary?.color ||
            props.theme.palette.primaryA1};
    }

    &.default {
        background: ${(props) => props.theme.palette.secondaryA9};
        color: ${(props) => props.theme.palette.textLight};
    }

    &.info {
        background: unset;
        color: ${(props) => props.theme.palette.textLight};
    }

    &.tooltip {
        cursor: pointer;
    }
    .badge-text {
        white-space: break-spaces;
    }

    .badge-left-addon {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        svg {
            max-width: 16px;
            max-height: 16px;
        }
    }

    .badge-left-addon + .badge-text {
        margin-left: 8px;
    }
`;
