import React from 'react';
import type { IconProps } from '@models/types';

export const PepSanctionsIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 10C2 7.19974 2 5.79961 2.54497 4.73005C3.02433 3.78924 3.78924 3.02433 4.73005 2.54497C5.79961 2 7.19974 2 10 2H12C14.8003 2 16.2004 2 17.27 2.54497C18.2108 3.02433 18.9757 3.78924 19.455 4.73005C19.9839 5.76805 19.9995 7.11742 20 9.7558C18.704 7.51085 16.2785 6 13.5 6C9.35786 6 6 9.35786 6 13.5C6 16.2785 7.51085 18.704 9.7558 20C7.11742 19.9995 5.76805 19.9839 4.73005 19.455C3.78924 18.9757 3.02433 18.2108 2.54497 17.27C2 16.2004 2 14.8003 2 12V10Z'
            fill='currentColor'
        />
        <circle cx='14' cy='14' r='5' stroke='currentColor' strokeWidth='2' />
        <path d='M17.5 17.5L22.5 22.5' stroke='currentColor' strokeWidth='2' />
    </svg>
);
