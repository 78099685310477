import type { TypographyProps } from '@components/atoms/typography/types';
import styled from 'styled-components';

export const TypographyWrapper = styled.span<
    Pick<TypographyProps, 'variant'> & { $color: TypographyProps['color'] }
>`
    ${(props) => {
        switch (props.variant) {
            case 'text':
                return props.theme.typography.text;
            case 'text-medium':
                return props.theme.typography.textMedium;
            case 'text-bold':
                return props.theme.typography.textBold;
            case 'small-text':
                return props.theme.typography.smallText;
            case 'small-text-bold':
                return props.theme.typography.smallTextBold;
            case 'big-text-semibold':
                return props.theme.typography.bigTextSemiBold;
            case 'big-text-regular':
                return props.theme.typography.bigTextRegular;
            case 'button':
                return props.theme.typography.button;
            case 'h1':
                return props.theme.typography.h1;
            case 'h2':
                return props.theme.typography.h2;
            case 'h3':
                return props.theme.typography.h3;
            default:
                return props.theme.typography.text;
        }
    }};
    color: ${(props) => {
        switch (props.$color) {
            case null:
                return undefined;
            case 'inherit':
                return 'inherit';
            case 'text-main':
                return props.theme.palette.textMain;
            case 'text-light':
                return props.theme.palette.textLight;
            case 'primary-a1':
                return props.theme.palette.primaryA1;
            case 'primary-a2':
                return props.theme.palette.primaryA2;
            case 'primary-a3':
                return props.theme.palette.primaryA3;
            case 'primary-a4':
                return props.theme.palette.primaryA4;
            case 'primary-a5':
                return props.theme.palette.primaryA5;
            case 'primary-a6':
                return props.theme.palette.primaryA6;
            case 'primary-a7':
                return props.theme.palette.primaryA7;
            case 'secondary-a1':
                return props.theme.palette.secondaryA1;
            case 'secondary-a2':
                return props.theme.palette.secondaryA2;
            case 'secondary-a3':
                return props.theme.palette.secondaryA3;
            case 'secondary-a4':
                return props.theme.palette.secondaryA4;
            case 'secondary-a5':
                return props.theme.palette.secondaryA5;
            case 'secondary-a6':
                return props.theme.palette.secondaryA6;
            case 'secondary-a7':
                return props.theme.palette.secondaryA7;
            case 'secondary-a8':
                return props.theme.palette.secondaryA8;
            default:
                return props.theme.palette.textMain;
        }
    }};
`;
