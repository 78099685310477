import React from 'react';
import type { IconProps } from '@models/types';

export const ArrowBottomSmallIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.52827 16.4715C9.26792 16.2111 9.26792 15.789 9.52827 15.5287L13.0569 12.0001L9.52827 8.47149C9.26792 8.21114 9.26792 7.78903 9.52827 7.52868C9.78862 7.26833 10.2107 7.26833 10.4711 7.52868L14.4711 11.5287C14.7314 11.789 14.7314 12.2111 14.4711 12.4715L10.4711 16.4715C10.2107 16.7318 9.78862 16.7318 9.52827 16.4715Z'
            fill='currentColor'
        />
    </svg>
);
