import { initReactI18next } from 'react-i18next';
import { LangEnum } from '@models/enums';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export const AVAILABLE_LANGUAGES = [
    LangEnum.EN,
    LangEnum.RU,
    LangEnum.ES,
    LangEnum.PT,
    LangEnum.DE,
];

type JsonsProps = {
    [key: string]: {
        [key: string]: string;
    };
};

const getAllJsons = (lang: LangEnum) => {
    if (lang === LangEnum.EN) {
        const context = require?.context(`/src/modules/translations/dashboard`, true, /en.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    if (lang === LangEnum.PT) {
        const context = require?.context(`/src/modules/translations/dashboard`, true, /pt.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    if (lang === LangEnum.RU) {
        const context = require?.context(`/src/modules/translations/dashboard`, true, /ru.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    if (lang === LangEnum.ES) {
        const context = require?.context(`/src/modules/translations/dashboard`, true, /es.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    if (lang === LangEnum.DE) {
        const context = require?.context(`/src/modules/translations/dashboard`, true, /de.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    return {};
};

const getFlatJsons = (jsons: JsonsProps) => {
    let obj = {};

    Object.values(jsons).forEach((item) => {
        obj = {
            ...obj,
            ...item,
        };
    });

    return obj;
};

export const createLocalization = (languages = [LangEnum.EN], lng?: LangEnum) => {
    // change this constant for testing translations (e.g. add 'local' to test new translations before pushing new translations)
    if (['development', 'production'].includes(process.env?.REACT_APP_TYPE || '')) {
        // for production/staging
        i18n.use(LanguageDetector)
            .use(initReactI18next)
            .use(Backend)
            .init({
                fallbackLng: LangEnum.EN,
                backend: {
                    loadPath: 'https://cdn.n37z.com/i18n/translations/dashboard/{{lng}}.json',
                },
                lng,
                interpolation: {
                    escapeValue: false,
                },
            });
    } else {
        const resources = languages.reduce(
            (acc, item) => ({
                ...acc,
                [item]: {
                    translation: getFlatJsons(getAllJsons(item)),
                },
            }),
            {},
        );

        i18n.use(LanguageDetector)
            .use(initReactI18next)
            .init({
                fallbackLng: LangEnum.EN,
                resources,
                lng,
                interpolation: {
                    escapeValue: false,
                },
            });
    }
};
