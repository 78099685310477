import React from 'react';
import type { IconProps } from '@models/types';

export const CheckIcon: React.FC<IconProps> = (props) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <path
            d='M11.1432 16.216C10.7529 16.5959 10.1234 16.5945 9.73487 16.2129L6.32075 12.8071C5.88425 12.3784 5.8946 11.6804 6.34362 11.2644C6.77178 10.8677 7.44265 10.8744 7.86254 11.2795L10.4375 13.8153L16.094 8.31854C16.5303 7.89355 17.234 7.89386 17.6699 8.31924C18.1104 8.74911 18.11 9.4491 17.6689 9.87841L11.1432 16.216Z'
            fill='currentColor'
        />
    </svg>
);
