import { LinkStyled } from '@components/atoms/link/styles';
import { createGlobalStyle, css } from 'styled-components';

const styles = css`
    .MuiTooltip-popper {
        &.tooltip-popper {
            .MuiTooltip-tooltip {
                background-color: ${(props) => props.theme.palette.textMain};
                color: ${(props) => props.theme.palette.primaryA7};
                ${(props) => props.theme.typography.smallTextBold};
                padding: 8px 12px;
                border-radius: 8px;

                ul {
                    padding-left: 12px;
                    margin-bottom: 0;
                }

                    ${LinkStyled}   {
                            color ${(props) => props.theme.palette.primaryA3}
                    }
            }

            .MuiTooltip-arrow {
                color: ${(props) => props.theme.palette.textMain};
            }
        }
    }
`;

export const TooltipGlobalStyles = createGlobalStyle`${styles}`;
