import React from 'react';
import type { IconProps } from '@models/types';

export const ArrowMediumIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <path d='M2 12H22M2 12L10.5 3.5M2 12L10.5 20.5' stroke='currentColor' strokeWidth='1.5' />
    </svg>
);
