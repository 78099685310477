import type { BaseThemeProps, BreakPointType, MediaQueriesType } from '@models/types';
import type { ThemeOptions } from '@mui/material/styles/createTheme';

export const theme: ThemeOptions = {
    typography: {
        fontFamily: `'Funnel Display', Roboto, sans-serif`,
        body1: {
            fontFamily: `'Funnel Display', Roboto, sans-serif`,
        },
        body2: {
            fontFamily: `'Funnel Display', Roboto, sans-serif`,
        },
        caption: {
            fontFamily: `'Funnel Display', Roboto, sans-serif`,
        },
        button: {
            fontFamily: `'Funnel Display', Roboto, sans-serif`,
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '18px',
            textTransform: 'none',
        },
        h1: {
            fontFamily: `'Funnel Display', Roboto, sans-serif`,
            fontSize: '50px',
            fontWeight: '700',
            lineHeight: '58px',

            '@media (max-width: 960px)': {
                fontSize: '44px',
                fontWeight: '600',
                lineHeight: '56px',
            },
        },
        h2: {
            fontFamily: `'Funnel Display', Roboto, sans-serif`,
            fontSize: '28px',
            fontWeight: '600',
            lineHeight: '36px',

            '@media (max-width: 960px)': {
                fontSize: '22px',
                lineHeight: '32px',
            },
        },
        h3: {
            fontFamily: `'Funnel Display', Roboto, sans-serif`,
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '28px',

            '@media (max-width: 960px)': {
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '24px',
            },
        },
        text: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '400',
            fontFamily: `'Figtree', sans-serif`,

            '@media (max-width: 960px)': {
                fontSize: '12px',
                lineHeight: '16px',
            },
        },
        textMedium: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '500',
            fontFamily: `'Figtree', sans-serif`,
            '@media (max-width: 960px)': {
                fontSize: '12px',
                lineHeight: '16px',
            },
        },
        textBold: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '600',
            fontFamily: `'Figtree', sans-serif`,

            '@media (max-width: 960px)': {
                fontSize: '12px',
                lineHeight: '16px',
            },
        },

        smallText: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '400',
            fontFamily: `'Figtree', sans-serif`,
        },
        smallTextBold: {
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '16px',
            fontFamily: `'Figtree', sans-serif`,
        },
        bigTextSemiBold: {
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
            fontFamily: `'Figtree', sans-serif`,
        },
        bigTextBold: {
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '24px',
            fontFamily: `'Figtree', sans-serif`,
        },
        bigTextRegular: {
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            fontFamily: `'Figtree', sans-serif`,
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: '#B04A46', position: 'relative', right: 2 },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    border: '1px solid #B7BCBF',
                    padding: 10,

                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },

                    '& .MuiOutlinedInput-input': {
                        border: 'none',
                        padding: 0,
                    },
                },
            },
        },
    },
};

export function generateMediaHelpers(currentTheme: BaseThemeProps): MediaQueriesType {
    const breakpointTo: Partial<Record<BreakPointType, string>> = {};
    const breakpointFrom: Partial<Record<BreakPointType, string>> = {};
    const breakpointBetween: Partial<
        Record<BreakPointType, Partial<Record<BreakPointType, string>>>
    > = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(currentTheme.breakPoints)) {
        breakpointTo[key as BreakPointType] = `@media (max-width: ${value})`;
        breakpointFrom[key as BreakPointType] = `@media (min-width: calc(${value} + 1px))`;
        // eslint-disable-next-line no-restricted-syntax
        for (const [keyInside, valueInside] of Object.entries(currentTheme.breakPoints)) {
            if (key !== keyInside) {
                if (!breakpointBetween[key as BreakPointType]) {
                    breakpointBetween[key as BreakPointType] = {};
                }
                const breakpoint = breakpointBetween[key as BreakPointType];

                if (breakpoint !== undefined) {
                    breakpoint[
                        keyInside as BreakPointType
                    ] = `@media ((max-width: ${valueInside}) and (min-width: calc(${value} + 1px)))`;
                }
            }
        }
    }

    return {
        breakpointTo: breakpointTo as MediaQueriesType['breakpointTo'],
        breakpointFrom: breakpointFrom as MediaQueriesType['breakpointFrom'],
        breakpointBetween: breakpointBetween as MediaQueriesType['breakpointBetween'],
        orientation: {
            landscape: '@media screen and (orientation: landscape)',
        },
    };
}

export const lightTheme: BaseThemeProps = {
    palette: {
        textMain: '#000000',
        textLight: '#737390',
        primaryA1: '#6664E8',
        primaryA2: '#5A58DF',
        primaryA3: '#D0D0FC',
        primaryA4: '#D8DEFB',
        primaryA5: '#E0E0FA',
        primaryA6: '#E6EDFB',
        primaryA7: '#FFFFFF',
        secondaryA1: '#F6CDD9',
        secondaryA2: '#F6F0C0',
        secondaryA3: '#52C27F',
        secondaryA4: '#FF5050',
        secondaryA5: '#FFFCFD',
        secondaryA6: '#FABC46',
        secondaryA7: '#B25DF5',
        secondaryA8: '#BBBAC9', // ?
        secondaryA9: '#DEE1F2', // ?
        secondaryA10: '#F6F8FE', // ?
    },
    components: {
        sideMenu: {
            opened: '260px',
            closed: '88px',
            backgroundColor: '#F0F0FD',
            menuItem: {
                color: '#747391',
                hover: {
                    color: '#6764E8',
                    backgroundColor: '#DAD9F9',
                },
                selected: {
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                },
                open: {
                    backgroundColor: '#e1e7fc',
                    color: '#000000',
                },
            },
        },
    },
    breakPoints: {
        xs: '575px',
        sm: '767px',
        md: '960px',
        lg: '1440px',
        xl: '1920px',
    },
    isLandscape: false,
};
